<template>
    <div v-if="ruleForm && ruleForm.id">
        <div class="page-header">审批详情</div>
        <div class="zt-block">
            <div class="zt-block-head">基本信息</div>
            <div class="list">
                <div class="flex">
                    <div class="left">法人姓名</div>
                    <div style="margin-right:20px">{{ruleForm.info.legalName}}</div>
                    <el-checkbox disabled v-model="ruleForm.info.isYb">一般纳税人</el-checkbox>
                </div>
                <div class="flex">
                    <div class="left">企业现有厂房面积</div>
                    <div>{{ruleForm.info.area}}</div>
                    <div style="margin-left:15px">M²</div>
                </div>
                <div class="flex">
                    <div class="left">当年的申请单元数</div>
                    <div>{{ruleForm.info.unitCount}}</div>
                </div>
                <div class="flex">
                    <div class="left">当年的税收</div>
                    <div>{{ruleForm.info.taxAmount}}</div>
                    <div style="margin-left:15px">万元</div>
                </div>
                <div class="flex">
                    <div class="left">现有厂房所在地</div>
                    <div>{{ruleForm.info.address}}</div>
                </div>
                <div class="flex">
                    <div class="left">企业员工数</div>
                    <div>{{ruleForm.info.staffCount}}</div>
                </div>
                <div class="flex">
                    <div class="left">主要营业收入</div>
                    <div>{{ruleForm.info.revenue}}</div>
                    <div style="margin-left:15px">万元</div>
                </div>
                <div class="flex" style="min-height:80px;">
                    <div class="left">备注</div>
                    <div>{{ruleForm.info.desc || '无'}}</div>
                </div>
            </div>
        </div>
        <div class="zt-block">
            <div class="zt-block-head">基本信息</div>
            <div class="list">
                <div class="flex">
                    <div class="left">用电表号</div>
                    <div>{{ruleForm.info.meters}}</div>
                </div>
            </div>
        </div>
        <div style="padding: 10px 20px 30px;">
            <el-button @click="back()" style="width: 75px;" size="small" type="primary">返回</el-button>
        </div>
    </div>
</template>
<script>
    import { findItem } from "@/util";
    import { getFactoryZones } from "@/service/dictionary";
    import { getApplyId } from "@/service/bureau";
    export default {
        components: {
            
        },
        data() {
            return {
                empOptions: [
                    { id: 1, label: '1-20人' }, 
                    { id: 2, label: '21-50人' }, 
                    { id: 3, label: '51-100人' }, 
                    { id: 4, label: '101-200人' }, 
                    { id: 5, label: '201-500人' }, 
                    { id: 6, label: '501-1000人' }, 
                    { id: 7, label: '1001人以上' }
                ],
                zoneOptions: [],
                ruleForm:null,
            }
        },
        async created() {
            this.zoneOptions = await getFactoryZones();
            this.getInfo();
        },
        mounted() {
            
        },
        methods: {
            back(link) {
                this.$router.go(-1);
            },
            getInfo() {
                getApplyId(this.$route.params.id).then(rst => {
                    this.ruleForm = rst;
                    // if (this.ruleForm && this.ruleForm.info.address) {
                    //     this.ruleForm.info.address = findItem(this.zoneOptions,this.ruleForm.info.address).name;
                    // }
                    if (this.ruleForm && this.ruleForm.info.staffCount) {
                        this.ruleForm.info.staffCount = findItem(this.empOptions,this.ruleForm.info.staffCount).label;
                    }
                });
            },
        }
    }
</script>
<style scoped>
    .list {
        padding: 16px 45px;
    }
    .list > div {
        font-size: 14px;
        color: #4d4d4d;
        line-height: 40px;
    }
    .list > div div.left {
        width: 120px;
        text-align: left;
        font-size: 14px;
        color: #808080;
        margin-right: 15px;
    }
</style>